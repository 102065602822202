<template>
  <v-container fluid class="custom-container-details">
    <div>
      <v-row v-if="rest && rest.isSystem" dense no-gutters class="px-0">
        <v-col cols="12">
          <v-alert color="secondary" data-cy="isSystem-hint">{{ $lang.hints.resourceIsSystem }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters>
        <v-col
          v-if="!userCanEdit && !loading && (rest && !rest.isSystem)"
          cols="12"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
        >
          <v-tabs
            v-model="tab"
            class="custom-tab-design"
            background-color="transparent"
          >

            <v-tab
              :key="0"
              data-cy="tab-details"
              class="ml-2"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-advanced"
              class="ml-2"
            >
              {{ $lang.labels.advanced }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-validations"
              class="ml-2"
            >
              {{ $lang.labels.validations }}
            </v-tab>
            <v-tab
              :key="3"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="4"
              data-cy="tab-history"
              class="ml-2"
            >
              {{ $lang.labels.history }}
            </v-tab>
            <v-tab
              :key="5"
              data-cy="tab-comment"
              class="ml-2"
            >
              {{ $lang.labels.comment }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class=" custom-bg pt-6" >
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-1 fill-height custom-bg" >
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="10"
                      class="pr-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="rest.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          class="required-asterisk copy-name-icon mb-3"
                          :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                          append-icon="mdi-vector-combine"
                          @click:append="copyName()"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="2" class="pl-1 text-right">
                      <v-btn
                        outlined

                        color="primary"
                        :disabled="!userCanEdit"
                        data-cy="status-change"
                        @click="changeStatus()"
                      >
                        <v-icon
                          left
                          dark
                        >
                          {{ rest.status === 'ACTIVE' ? 'mdi-toggle-switch-off ' : 'mdi-toggle-switch' }}
                        </v-icon>
                        {{ rest.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-4 mt-sm-0">
                    <v-col
                      cols="3"
                      class="pr-1 pr-sm-0"
                    >
                      <v-select
                        v-model="rest.method"
                        :items="formatMethods"
                        :label="$lang.labels.method"
                        outlined
                        dense
                        :rules="[v => !!v || $lang.labels.required]"
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                        data-cy="rest-method"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item.value">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="8"
                      class="px-sm-1"
                    >
                      <v-text-field
                        v-model="rest.url"
                        outlined
                        dense
                        :label="$lang.labels.url"
                        required
                        :rules="[v => !!v || $lang.labels.required, v => rest.isSystem ? true : !/^\/system\//.test(v) || $lang.errors.urlStartsWithSystem]"
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                        data-cy="rest-url"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="pr-sm-1"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': rest.url, 'color-secondary': !rest.url}"
                        data-cy="copy-url"
                        @click="rest.url ? copyUrl() : ''"
                      >
                        {{ $lang.actions.copyUrl }}
                      </p>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="3"
                    >
                      <v-text-field
                        v-model="tempMaxRequestSize"
                        outlined
                        dense
                        :label="$lang.labels.maxRequestSize"
                        :rules="[v => !!v || $lang.labels.required]"
                        class="required-asterisk"
                        :readonly="!userCanEdit"
                        data-cy="rest-maxRequestSize"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="3"
                      class="pl-1 px-sm-1"
                    >
                      <v-select
                        v-model="sizeUnit"
                        :items="sizeUnits"
                        :label="$lang.labels.unit"
                        outlined
                        dense
                        :readonly="!userCanEdit"
                        data-cy="rest-sizeUnit"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item">{{ item }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="11"
                      class="pr-1"
                    >
                      <v-autocomplete
                        v-model="rest.processId"
                        outlined
                        dense
                        :items="processes"
                        :loading="isLoadingProcesses"
                        :search-input.sync="searchProcesses"
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        :label="$lang.labels.process"
                        :placeholder="$lang.actions.startTyping"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        required
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                        data-cy="rest-processId"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item.id">{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      class="align-center"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': rest.processId, 'color-secondary': !rest.processId}"
                        data-cy="open-process"
                        @click="rest.processId ? openProcess() : ''"
                      >
                        {{ $lang.actions.openProcess }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-inline-flex"
                    >
                      <v-checkbox v-model="rest.basicAccessAuthentication" data-cy="rest-basic" :label="$lang.labels.basicAccessAuthentication"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-divider class="mb-4" />
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          outlined
                          color="primary"
                          data-cy="rest-back"
                          :to="{ name: 'rest', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          outlined
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="rest.name"
                          :title="$lang.actions.delete"
                          type="rest"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'rest-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          :simple-error="err"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteRestFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'rest' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-inline-flex">
                        <v-btn
                          outlined
                          :disabled="!isFormValid || !isEdit"
                          color="primary"
                          class="mr-1 color-accent-text"
                          @click="copyCreateResource(rest, 'restCreate', $router, '', $options.filters.formatDateTime(new Date()), false, true)"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-content-copy
                          </v-icon>
                          {{ $lang.actions.copyCreate }}
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="showCreateProcessForTriggerButton"
                          :action-text="$lang.actions.triggerCreateProcess"
                          :title="$lang.actions.createProcess"
                          :is-disabled="!isUserProcessCreator || !valid || lock"
                          :button-text="$lang.actions.submit"
                          :button-color="'success'"
                          :data-cy="'rest-create-process'"
                          :simple-error="err"
                          :btn-icon="'mdi-floppy'"
                          class="ml-2"
                          @submit="createProcessForTrigger()"
                          @closeDialog="() => err = $lang.errors.processRequired"
                        />
                        <v-btn
                          v-else
                          :disabled="!isFormValid"
                          color="primary"

                          data-cy="rest-submit"
                          @click="submit()"
                        >
                          <v-icon
                            left
                            dark
                            class="mr-1"
                          >
                            mdi mdi-floppy
                          </v-icon>
                          {{ $lang.actions.submit }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-select
                      v-model="rest.priority"
                      :items="priorities"
                      :label="$lang.labels.priority"
                      outlined
                      dense
                      :rules="[v => !!v || $lang.labels.required]"
                      :readonly="!userCanEdit"
                      class="required-asterisk"
                      data-cy="rest-priority"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title :data-cy="item">{{ item }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-combobox
                      v-model="rest.instanceIds"
                      outlined
                      dense
                      multiple
                      chips
                      :items="instances"
                      :label="$lang.labels.instances"
                      prepend-inner-icon="mdi-cloud-search-outline"
                      clearable
                      :readonly="!userCanEdit"
                      data-cy="rest-instanceIds"
                      :rules="[v => v.length < 49 || 'Max 48 instances']"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title :data-cy="item">{{ item }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-inline-flex"
                  >
                    <v-checkbox v-model="rest.isGdprRelevant" data-cy="rest-gdpr" :label="$lang.labels.gdpr"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row no-gutters align="center">
                  <v-col cols="12" flat>
                    <template v-for="(rule, n) in rest.validationRules">
                      <v-row :key="validationKey + n" no-gutters align="center">
                        <v-col cols="12" class="align-center">
                          <div class="d-inline-flex align-center" style="width: 100%">
                            <validations-search-field
                              :item-validation="rule"
                              :item-index="n"
                              :user-can-edit="userCanEdit"
                              :label="$lang.status[rule.type]"
                              :required="false"
                              :resource-is-system="rest.isSystem"
                              @validationRuleIdChanged="() => validationKey = validationKey++"
                              @onChangeValidationSearchResults="(result) => validationRules = [...validationRules, ...result]"
                            />
                            <v-btn
                              text
                              style="margin-bottom: 32px !important"
                              :class="{'p-0 pl-2 clickable-simple': true, 'color-primary': rule.validationRuleId, 'color-secondary': !rule.validationRuleId}"
                              data-cy="clear-validation"
                              color="primary"
                              @click="rule.validationRuleId = ''; validationKey = validationKey++"
                            >
                              {{ $lang.actions.clear }}
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="3"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="4"
              class="fill-height"
            >
              <v-card class="py-1 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreRest($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="5"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col
                    cols="12"
                  >
                    <div class="d-flex justify-space-between">
                      <p class="pa-0 ma-0">{{ $lang.labels.comment }}</p>
                      <p
                        class="pa-0 ma-0 clickable-simple color-primary"
                        data-cy="edit-comment"
                        @click="lock || !userCanEdit ? '' : showMarkdown = true"
                      >
                        {{ $lang.actions.edit }}
                      </p>
                    </div>
                    <div class="mark-class pa-1 mb-2 mt-1 clickable-simple" data-cy="rest-comment" @click="lock || !userCanEdit ? '' : showMarkdown = true" v-html="compiledMarkdown"></div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="showMarkdown" v-model="showMarkdown" max-width="80%" width="80%">
        <markdown-modal
          :markdown-data="rest.comment"
          @save="rest.comment = $event"
          @closeDialog="showMarkdown = false"
        />
      </v-dialog>
      <multi-errors-snackbar
        :show="showSnackErrors"
        :errors="errorsForSnackbar"
        @close="showSnackErrors = false; errorsForSnackbar = []"
      />
    </div>
  </v-container>
</template>

<script>
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import copy from 'copy-to-clipboard'
import MarkdownModal from '@/components/ui/modals/MarkdownModal'
import {
  getTriggerRestByIdUsingGET as getRest,
  disableTriggerRestUsingGET as disableRest,
  enableTriggerRestUsingGET as enableRest,
  updateTriggerRestUsingPUT as updateRest,
  createTriggerRestUsingPOST as createRest,
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess,
  getRolesUsingGET as getRoles,
  triggerRestCheckUsingGET as triggerRestCheck,
  getHistoriesUsingGET as getHistories,
  getGlobalSettingsUsingGET as getSettings,
  getGlobalSettingByIdUsingGET as getSingleSetting,
  deleteRestTriggerUsingDELETE as deleteRestTrigger,
  getInstancesUsingGET as getInstances,
  getEncodingTypesUsingGET as getEncodingTypes
} from '@/utils/api'
import {
  predictBestSizeUnitFromBytesValue,
  canUserEditResource,
  doesProcessNameExist,
  createProcessForTriggerData,
  copyCreateResource
} from '@/utils/helpers'
import { marked } from 'marked'
// import AlertMessage from '@/components/ui/AlertMessage.vue'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import ValidationsSearchField from '../validation/ValidationsSearchField.vue'
import { mapActions } from 'vuex'
import { bus } from '@/main'

export default {
  components: {
    ActionButtonWithConfirmation,
    MultiErrorsSnackbar,
    Table,
    MarkdownModal,
    UserRolesSelect,
    ValidationsSearchField
  },
  provide() {
    return {
      openValidation: this.openRule
    }
  },
  data() {
    return {
      showMarkdown: false,
      validationKey: 10000,
      tab: 0,
      errUrlValidation: '',
      errPathVarValidation: '',
      err: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      isLoadingProcesses: false,
      lock: false,
      rest: {
        createdOn: '',
        id: '',
        maxRequestSize: '',
        modifiedOn: '',
        name: '',
        comment: '',
        processId: '',
        status: '',
        method: '',
        url: '',
        isGdprRelevant: false,
        roles: [],
        validationRules: [],
        basicAccessAuthentication: false,
        priority: 64,
        instanceIds: [],
        isSystem: false
      },
      instances: [],
      methods: ['DELETE', 'GET', 'PATCH', 'POST', 'PUT'],
      processes: [],
      searchProcesses: '',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      sizeUnits: ['Bytes', 'Kilobytes', 'Megabytes'],
      sizeUnit: 'Kilobytes',
      tempMaxRequestSize: '',
      isSuperUser: false,
      ruleTypes: ['BODY', 'HEADERS', 'QUERY_PARAMS', 'PATH_VARIABLES'],
      validationRules: [],
      isLoadingValidationRules: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      isUserProcessCreator: false,
      showCreateProcessDialog: false
    }
  },
  computed: {
    priorities() {
      return Array.from({ length: 128 }, (_, index) => index + 1)
    },
    compiledMarkdown: function() {
      return marked.parse(this.rest?.comment) || ''
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatMethods() {
      return this.methods.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.rest.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.rest.modifiedOn)}, ID: ${this.rest.id}`
    },
    restRoles() {
      return [
        ...this.editRolesIds.map((x) => ({ permissionType: 'EDIT', roleId: x })),
        ...this.useRolesIds.map((x) => ({ permissionType: 'USE', roleId: x })),
        ...this.viewRolesIds.map((x) => ({ permissionType: 'VIEW', roleId: x }))
      ]
    },
    showCreateProcessForTriggerButton() {
      return this.isUserProcessCreator && !this.rest.processId
    },
    isFormValid() {
      return this.valid && !this.lock && this.userCanEdit
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val)
    },
    tab(val) {
      if (val === 4) this.fetchHistory()
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    }
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  created() {
    this.isEdit = this.$route.name === 'restEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'TRIGGER_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        this.isUserProcessCreator = !!user.roles.find((x) => x.name === 'PROCESS_CREATOR')
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getRest({ id: this.$route.params.id })
            .then((res) => {
              this.rest = res.data.data

              this.editRolesIds = this.rest.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.rest.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.rest.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.canUserEditResource(this.isSuperUser, !!this.editRolesIds.find((x) => this.userRolesIds.includes(x)), this.rest.isSystem)

              if (this.rest.comment === null) {
                this.rest.comment = ''
              }

              if (this.rest.priority === null || this.rest.priority === undefined) {
                this.rest.priority = 64
              }

              this.formatSizeUnits()

              if (!this.rest.validationRules) this.rest.validationRules = []

              this.validationRules = JSON.parse(JSON.stringify(this.rest.validationRules.map((x) => x.validationRule)))

              const tempValidationRules = []

              this.ruleTypes.forEach((rule, index) => {
                const tryFind = this.rest.validationRules.find((x) => x.type === rule)

                if (!tryFind) {
                  tempValidationRules.push({ type: rule, validationRuleId: '' })
                } else {
                  tempValidationRules.push({ type: rule, validationRuleId: tryFind.validationRule.id })
                }
              })

              this.rest.validationRules = tempValidationRules

              if (res.data.data.processId !== null) {
                getProcess({ id: res.data.data.processId })
                  .then((res) => {
                    this.processes = [res.data.data]

                    this.loading = false
                  })
                  .catch((err) => {
                    this.addSnackbar({
                      message: err.message,
                      timeout: 5000,
                      color: 'error'
                    })
                  })
              } else {
                this.loading = false
                this.addSnackbar({
                  message:  'Process not found',
                  timeout: 5000,
                  color: 'error'
                })
              }

            })
            .catch((err) => {
              this.addSnackbar({
                message:  err,
                timeout: 5000,
                color: 'error'
              })
            })
        } else if (this.$route.params.restore) {
          this.userCanEdit = true
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.rest = this.$route.params.restore
          this.rest.id = 0

          this.rest.name = this.$route.params.restore.name

          if (this.rest.comment === null) {
            this.rest.comment = ''
          }

          if (this.rest.priority === null || this.rest.priority === undefined) {
            this.rest.priority = 64
          }

          this.formatSizeUnits()

          if (!this.rest.validationRules) this.rest.validationRules = []

          this.validationRules = JSON.parse(JSON.stringify(this.rest.validationRules))

          const tempValidationRules = []

          this.ruleTypes.forEach((rule, index) => {
            const tryFind = this.rest.validationRules.find((x) => x.type === rule)

            if (!tryFind) {
              tempValidationRules.push({ type: rule, validationRuleId: '' })
            } else {
              tempValidationRules.push({ type: rule, validationRuleId: tryFind.validationRule.id })
            }
          })

          this.rest.validationRules = tempValidationRules

          getProcess({ id: this.$route.params.restore.processId })
            .then((res) => {
              this.processes = [res.data.data]

              this.loading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message:  err,
                timeout: 5000,
                color: 'error'
              })             })
        } else {
          this.userCanEdit = true
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          if (this.$route.params.copy) {
            getRest({ id: this.$route.params.copy.id })
              .then((res) => {
                this.rest = res.data.data

                this.rest.name = this.$route.params.copy.name

                this.editRolesIds = this.rest.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
                this.useRolesIds = this.rest.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
                this.viewRolesIds = this.rest.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

                this.userCanEdit = this.canUserEditResource(this.isSuperUser, !!this.editRolesIds.find((x) => this.userRolesIds.includes(x)), this.rest.isSystem)

                if (this.rest.comment === null) {
                  this.rest.comment = ''
                }

                if (this.rest.priority === null || this.rest.priority === undefined) {
                  this.rest.priority = 64
                }

                this.formatSizeUnits()

                if (!this.rest.validationRules) this.rest.validationRules = []

                this.validationRules = JSON.parse(JSON.stringify(this.rest.validationRules))

                const tempValidationRules = []

                this.ruleTypes.forEach((rule, index) => {
                  const tryFind = this.rest.validationRules.find((x) => x.type === rule)

                  if (!tryFind) {
                    tempValidationRules.push({ type: rule, validationRuleId: '' })
                  } else {
                    tempValidationRules.push({ type: rule, validationRuleId: tryFind.validationRule.id })
                  }
                })

                this.rest.validationRules = tempValidationRules

                getProcess({ id: res.data.data.processId })
                  .then((res) => {
                    this.processes = [res.data.data]

                    this.loading = false
                  })
                  .catch((err) => {
                    this.addSnackbar({
                      message:  err,
                      timeout: 5000,
                      color: 'error'
                    })                   })

              })
              .catch((err) => {
                this.addSnackbar({
                  message:  err,
                  timeout: 5000,
                  color: 'error'
                })})
          } else {
            this.userCanEdit = true
            this.ruleTypes.forEach((rule) => {
              this.rest.validationRules.push({ type: rule, validationRuleId: '' })
            })
            this.loading = false
          }
        }
      })
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),
    canUserEditResource,
    copyCreateResource,
    removeDuplicates(arr, key) {
      const seen = new Set()

      return arr.filter((item) => {
        const keyValue = item[key]

        if (seen.has(keyValue)) {
          return false
        } else {
          seen.add(keyValue)

          return true
        }
      })
    },
    copyName() {
      if (this.rest.name) {
        copy(this.rest.name)

        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {

        this.addSnackbar({
          message: this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    deleteRestFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteRestTrigger({ id: this.rest.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    async formatSizeUnits() {
      if (this.rest && this.rest.maxRequestSize) {
        const unitMaxRequestSize = await predictBestSizeUnitFromBytesValue(this.rest.maxRequestSize)

        this.sizeUnit = unitMaxRequestSize.unit
        this.tempMaxRequestSize = unitMaxRequestSize.value
      }
    },
    openProcess() {
      if (this.rest.processId) window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.rest.processId}`, '_blank')
    },
    openRule(rule) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/validations/edit/${rule}`, '_blank')
    },
    copyUrl() {

      getSettings()
        .then((res) => {
          const data = res.data.data.find((x) => x.type === 'FRONTEND')

          if (!data) {

            this.addSnackbar({
              message: this.$lang.errors.noFeSettings,
              timeout: 5000,
              color: 'error'
            })

            return
          }

          getSingleSetting({ id: data.id })
            .then((res2) => {
              const baseUrl = res2.data.data.values.baseUrlRest
              const lastChar = baseUrl.slice(baseUrl.length - 1)
              const firstChar = this.rest.url ? this.rest.url.substring(0, 1) : ''

              const copyData = `${baseUrl}${lastChar === '/' ? '' : '/'}${firstChar === '/' ? this.rest.url.substring(1, this.rest.url.length) : this.rest.url}`

              if (copyData) {
                copy(copyData)

                this.addSnackbar({
                  message: this.$lang.success.copiedClipboard,
                  timeout: 5000,
                  color: 'success'
                })
              }
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchHistory(options) {
      if (!this.rest.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.rest.id
      obj.resourceType = 'TRIGGER_REST'

      getHistories(obj)
        .then((res) => {
          if (res.status !== 200) {

            this.addSnackbar({
              message: this.$lang.errors.historyFetch,
              timeout: 5000,
              color: 'error'
            })

            return
          }

          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    restoreRest(data) {
      this.rest = data

      if (!this.rest.comment) this.rest.comment = ''

      if (this.rest && this.rest.roles) {
        this.editRolesIds = this.rest.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.rest.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.rest.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.canUserEditResource(this.isSuperUser, !!this.editRolesIds.find((x) => this.userRolesIds.includes(x)), this.rest.isSystem)
      }

      this.formatSizeUnits()

      if (!this.rest.validationRules) this.rest.validationRules = []

      this.validationRules = JSON.parse(JSON.stringify(this.rest.validationRules))

      const tempValidationRules = []

      this.ruleTypes.forEach((rule, index) => {
        const tryFind = this.rest.validationRules.find((x) => x.type === rule)

        if (!tryFind) {
          tempValidationRules.push({ type: rule, validationRuleId: '' })
        } else {
          tempValidationRules.push({ type: rule, validationRuleId: tryFind.validationRule.id })
        }
      })

      this.rest.validationRules = tempValidationRules

      getProcess({ id: this.rest.processId })
        .then((res) => {
          this.processes = [res.data.data]

          this.loading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    async init() {
      const instances = await getInstances()

      this.instances = instances.data.data.items.filter((y) => y.type === 'PROCESSING').map((x) => x.name)

      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async checkUrl() {
      this.err = ''
      this.errPathVarValidation = ''
      this.errUrlValidation = ''
      this.lock = true

      let res = null

      try {

        res = await triggerRestCheck({ method: this.rest.method, url: this.rest.url })

        if (res && res.status !== 200) {
          const errorMessage = res?.response?.data?.statusText || this.$lang.errors.check

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })

          return false
        }

        if ((res.data.data.isExist && this.isEdit && this.rest.id !== res.data.data.triggerId) || (res.data.data.isExist && !this.isEdit)) {
          const errMessage = `${this.$lang.errors.checkIsExists} ${res.data.data.triggerId}`

          this.addSnackbar({
            message: errMessage,
            timeout: 5000,
            color: 'error'
          })

          return false
        }

        return true

      } catch (err) {

        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })

        return false
      }
    },
    checkUrlValidation() {
      const validationPresent = this.rest.validationRules[3].validationRuleId

      if (!validationPresent) return { areInValidation: [], areInUrl: [] }

      this.validationRules = this.removeDuplicates(this.validationRules, 'id')

      const pathVariableObject = this.validationRules.find((x) => x.id === validationPresent)

      const allValidationFields = pathVariableObject ? pathVariableObject.fields.map((x) => x.name) : []

      const allUrlFields = this.rest.url.match(/[^{}]+(?=})/g) || []

      const set1 = new Set(allValidationFields) // Validation fields
      const set2 = new Set(allUrlFields) // URL fields

      // Correct filtering logic:
      // Find elements in allUrlFields that are missing in set1 (shouldn't be in validation fields)
      const areInValidation = allUrlFields.filter((elem) => !set1.has(elem))

      // Find elements in allValidationFields that are missing in set2 (shouldn't be in URL fields)
      const areInUrl = allValidationFields.filter((elem) => !set2.has(elem))

      return {
        areInValidation: areInValidation.length > 0 ? areInValidation : [],
        areInUrl: areInUrl.length > 0 ? areInUrl : []
      }
    }
    ,
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || '',
        isSystem: this.rest?.isSystem || false
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    openCreateProcessDialog() {
      this.showCreateProcessDialog = true
    },
    //TODO maybe somehow DRY with same functions in other triggers
    async createProcessForTrigger() {
      const processNameExists = await doesProcessNameExist(this.rest.name)

      this.lock = true
      this.loading = true

      const res = await createProcessForTriggerData(this.rest.name, processNameExists, this.restRoles)

      if (res && res.status !== 200) {
        this.addSnackbar({
          message:  res?.response?.data?.statusText ||
          (this.$lang.errors.processCreate),
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
        this.loading = false
      } else if (res) {
        const processId = res.data.data.id
        const processName = res.data.data.name

        const existingProcess = this.processes.find((process) => process.id === processId)

        if (!existingProcess) {
          this.processes.push({ id: processId, name: processName })
        }

        this.rest.processId = processId
        // This to be enabled after we have snack that can queue; MD - 10..07.2024
        // Improve the success message to be clear that connected process is created
        // this.addSnackbar({
        //   message:  this.$lang.success.processCreated,
        //   timeout: 5000,
        //   color: 'success'
        // })
        this.lock = false
        this.loading = false
        this.showCreateProcessDialog = false
        this.submit()
      }
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {

        this.addSnackbar({
          message:  this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.lock = true

      const checkPassed = await this.checkUrl()

      if (!checkPassed) {
        this.lock = false

        return
      }

      const pathVariablesValidation = this.checkUrlValidation()

      if (pathVariablesValidation.areInUrl.length > 0 || pathVariablesValidation.areInValidation.length > 0) {
        const tempErrUrlString = pathVariablesValidation.areInUrl.join(', ')
        const tempErrPathString = pathVariablesValidation.areInValidation.join(', ')

        if (tempErrUrlString) {
          this.errUrlValidation = `${this.$lang.errors.triggerRestUrlValidation}: ${tempErrUrlString}`
        }
        if (tempErrPathString) {
          this.errPathVarValidation = `${this.$lang.errors.triggerRestPathVarValidation}: ${tempErrPathString}`
        }

        this.addSnackbar({
          message:  `${this.errUrlValidation}${this.errUrlValidation && this.errPathVarValidation ? '. ' : ''}${this.errPathVarValidation}`,
          timeout: 30000,
          color: 'error'
        })
        setTimeout(() => {
          this.errUrlValidation = ''
          this.errPathVarValidation = ''
        },
        30000)

        this.lock = false

        return
      }

      const copyOfRest = JSON.parse(JSON.stringify(this.rest))

      let res = null

      const { id } = copyOfRest

      if (!this.isEdit) {
        delete copyOfRest.id
        copyOfRest.status = 'ACTIVE'
      }
      delete copyOfRest.createdOn
      delete copyOfRest.modifiedOn

      copyOfRest.roles = this.restRoles

      copyOfRest.maxRequestSize = this.$options.filters.sizeUnitFormatter(this.tempMaxRequestSize, this.sizeUnit, 'Bytes')

      copyOfRest.validationRules = copyOfRest.validationRules.filter((x) => x.validationRuleId)

      try {

        res = this.isEdit ? await updateRest({ id, body: copyOfRest }) : await createRest({ body: copyOfRest })

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {
            const error = res?.response?.data

            this.err = error?.statusText || (this.isEdit ? this.$lang.errors.restUpdate : this.$lang.errors.restCreate)
            if (this.err === 'Validation failed') {
              this.err = `${this.err}: ${error?.data?.[0].field} - ${error?.data?.[0].error}`

            }
            this.addSnackbar({
              message:  this.err,
              timeout: 5000,
              color: 'error'
            })
            setTimeout(() => this.err = '', 5000)
          }
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.restUpdated : this.$lang.success.restCreated

        this.addSnackbar({
          message:  successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false

        if (!this.isEdit) {
          this.rest = res.data.data
          this.isEdit = true
          this.$router.push({
            name: 'restEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message:  err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.rest.status === 'ACTIVE' ? await disableRest({ id: this.rest.id }) : await enableRest({ id: this.rest.id })

        if (res.status !== 200) {

          this.addSnackbar({
            message:  this.$lang.errors.restStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false
        }
        const successMessage = this.rest.status !== 'ACTIVE' ? this.$lang.success.restActivated : this.$lang.success.restDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.rest.status = res.data.data.status

        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
</style>
